<template>
  <section class="app-main">
    <div class="container is-fluid is-marginless app-content">
      <nav class="level app-levelbar">
        <div class="level-left">
          <div class="level-item">
            <h3 class="subtitle is-5">
              <strong>Vie du magasin</strong>
            </h3>
          </div>
        </div>
        <div class="level-right is-hidden-mobile">
          <ol class="breadcrumb">
            <li>
              <a href="/#" class="is-active">Home</a>
            </li>
            <li>
              <span class="active">Vie du magasin</span>
            </li>
          </ol>
        </div>
      </nav>
      <div class="animated">
        <div class="tile is-ancestor">
          <div class="tile is-parent">
            <article class="tile is-child box">
              <p class="title">Mon prochain service</p>
              <p class="subtitle">
                Vendredi 24 décembre 19h00
                <span class="icon is-small">
                  <i class="fa fa-info"></i>
                </span>
              </p>
              <p>
                <span class="icon is-small">
                  <i class="fa fa-plus"></i>
                </span>
              </p>
            </article>
          </div>
          <div class="tile is-parent">
            <article class="tile is-child box">
              <p class="title">Chiffre d'affaire</p>
              <p class="subtitle">
                Cette semaine 100 000
                <span class="icon is-small">
                  <i class="fa fa-euro"></i>
                </span>
              </p>
            </article>
          </div>
          <div class="tile is-parent is-success">
            <article class="tile is-child box">
              <p class="title">Votre statut</p>
              <p class="subtitle">Vous ètes à jour</p>
            </article>
          </div>
        </div>
        <div class="tile is-ancestor">
          <div class="tile is-parent">
            <article class="tile is-child box">
              <div class="content">
                <p class="title">Messages</p>
                <p class="subtitle">Séances photo</p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ornare magna eros, eu pellentesque tortor vestibulum ut. Maecenas non massa sem. Etiam finibus odio quis feugiat facilisis.
                  <span
                    class="icon is-small"
                  >
                    <i class="fa fa-link"></i>
                  </span>
                </p>
                <p>
                  <span class="icon is-small">
                    <i class="fa fa-plus"></i>
                  </span>
                </p>
              </div>
            </article>
          </div>
        </div>
        <div class="tile is-ancestor">
          <div class="tile is-parent">
            <article class="tile is-child box">
              <div class="content">
                <p class="title">Prochaine AG</p>
                <p class="subtitle">Mardi 18 septembre</p>
                <p>
                  <a
                    href="https://docs.google.com/document/d/1A2qzcaOJtn9Pj3B6p8ugTyhZlQVY8qSSPMy-C6yWHmk/edit"
                    target="_blank"
                    class="button"
                  >Inscription / Ordre du Jour / Questions</a>
                </p>
              </div>
            </article>
          </div>
          <div class="tile is-parent">
            <article class="tile is-child box">
              <div class="content">
                <p class="title is-danger">Actuellement fermé</p>
                <p class="subtitle">Le magasin est ouvert le dimanche de 9h00 à 13h00.</p>
                <p>Ces horaires sont susceptibles de varier lors de certains évènements. Consultez les urgences pour en savoir plus</p>
              </div>
            </article>
          </div>
        </div>
        <div class="tile is-ancestor">
          <div class="tile is-parent">
            <article class="tile is-child box">
              <div class="content">
                <p class="title">Guides et manuels utiles</p>
                <div class="container is-fluid">
                  <div class="columns is-multiline">
                    <div class="column is-one-quarter">
                      <p class="is-vertical-center">Manuel des membres</p>
                      <p class="is-vertical-center">
                        <a href="pdfs/Manuel_membres_La_Louve.pdf" target="_blank">
                          <span class="icon">
                            <i class="fa fa-file fa-5x"></i>
                          </span>
                        </a>
                      </p>
                    </div>
                    <div class="column is-one-quarter">
                      <p class="is-vertical-center">Pour les coordinateurs</p>
                      <p class="is-vertical-center">
                        <a href="pdfs/Manuel_membres_La_Louve.pdf" target="_blank">
                          <span class="icon">
                            <i class="fa fa-file fa-5x"></i>
                          </span>
                        </a>
                      </p>
                    </div>
                    <div class="column is-one-quarter">
                      <p class="is-vertical-center">Exemple de feuille de présence</p>
                      <p class="is-vertical-center">
                        <a href="pdfs/Manuel_membres_La_Louve.pdf" target="_blank">
                          <span class="icon">
                            <i class="fa fa-file fa-5x"></i>
                          </span>
                        </a>
                      </p>
                    </div>
                    <div class="column is-one-quarter">
                      <p class="is-vertical-center">AG et comité Ordre du Jour</p>
                      <p class="is-vertical-center">
                        <a href="pdfs/Manuel_membres_La_Louve.pdf" target="_blank">
                          <span class="icon">
                            <i class="fa fa-file fa-5x"></i>
                          </span>
                        </a>
                      </p>
                    </div>
                    <div class="column is-one-quarter">
                      <p class="is-vertical-center">Guide de sécurité incendie</p>
                      <p class="is-vertical-center">
                        <a href="pdfs/Manuel_membres_La_Louve.pdf" target="_blank">
                          <span class="icon">
                            <i class="fa fa-file fa-5x"></i>
                          </span>
                        </a>
                      </p>
                    </div>
                    <div class="column is-one-quarter">
                      <p class="is-vertical-center">Statuts de la coopérative</p>
                      <p class="is-vertical-center">
                        <a href="pdfs/Manuel_membres_La_Louve.pdf" target="_blank">
                          <span class="icon">
                            <i class="fa fa-file fa-5x"></i>
                          </span>
                        </a>
                      </p>
                    </div>
                    <div class="column is-one-quarter">
                      <p class="is-vertical-center">Statuts de l’association des amis de La Louve</p>
                      <p class="is-vertical-center">
                        <a href="pdfs/Manuel_membres_La_Louve.pdf" target="_blank">
                          <span class="icon">
                            <i class="fa fa-file fa-5x"></i>
                          </span>
                        </a>
                      </p>
                    </div>
                    <div class="column is-one-quarter">
                      <p class="is-vertical-center">Le Modèle Park Slope</p>
                      <p class="is-vertical-center">
                        <a href="pdfs/Manuel_membres_La_Louve.pdf" target="_blank">
                          <span class="icon">
                            <i class="fa fa-file fa-5x"></i>
                          </span>
                        </a>
                      </p>
                    </div>
                    <div class="column is-one-quarter">
                      <p class="is-vertical-center">Venir au Supermarché</p>
                      <p class="is-vertical-center">
                        <a href="pdfs/Manuel_membres_La_Louve.pdf" target="_blank">
                          <span class="icon">
                            <i class="fa fa-file fa-5x"></i>
                          </span>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Vie",
  data: function() {
    return {
      mesinfos: ""
    };
  },
  mounted() {
    this.$http
      .get("/api/members/me", {
        headers: { Authorization: "Bearer " + localStorage.token }
      })
      .then(
        response => {
          this.mesinfos = response.data;
        },
        () => {
          this.$router.push("logout");
        }
      );
  }
};
</script>

<style lang="css" scoped>
.is-title {
  text-transform: capitalize;
}

.subtitle:not(:last-child),
.title:not(:last-child) {
  margin-bottom: 1.5rem;
}

.title {
  color: #363636;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.125;
}

.level-item .subtitle,
.level-item .title {
  margin-bottom: 0;
}

.subtitle.is-5 {
  font-size: 1.25rem;
}

.subtitle {
  color: #2e2e2e;
  font-weight: 300;
  line-height: 1.25;
}

.subtitle strong {
  color: #363636;
}

.subtitle strong,
.title strong {
  font-weight: 500;
}

.level-item {
  /* -ms-flex-align: center; */
  align-items: center;
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-preferred-size: auto; */
  flex-basis: auto;
  /* -ms-flex-positive: 0; */
  flex-grow: 0;
  /* -ms-flex-negative: 0; */
  flex-shrink: 0;
  /* -ms-flex-pack: center; */
  justify-content: center;
}

.breadcrumb {
  --separator: "\2044";
  list-style: none;
  /* -ms-flex-align: center; */
  align-items: center;
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-pack: end; */
  justify-content: flex-end;
}

.level-left .level-item:not(:last-child),
.level-right .level-item:not(:last-child) {
  margin-right: 0.75rem;
}

.is-vertical-center {
  text-align: center;
  padding-top: 10px;
}
</style>
